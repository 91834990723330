import { CSSProperties, useState } from 'react';

export interface DraggableRowProps {
  id: string;
  children: React.ReactNode;
  onChange?: (dragId: string, dropId: string, above: boolean) => void;
}

export const DraggableRow = (props: DraggableRowProps) => {
  type Position = 'above' | 'below' | 'none';
  const [dropPosition, setDropPosition] = useState<Position>('none');

  const handleDragStart = (e: React.DragEvent<HTMLSpanElement>) => {
    //e.dataTransfer.setDragImage(CreateDragImage(props.category.name), 10, 10);
    e.dataTransfer.setData('text', JSON.stringify({ id: props.id }));
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragEnd = () => {
    setDropPosition('none');
  };

  const handleDragOver = (e: React.DragEvent) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const y = e.clientY - rect.top;
    if (y < rect.height / 2) {
      setDropPosition('above');
    } else {
      setDropPosition('below');
    }
    e.preventDefault();
  };

  const handleDragLeave = (e: React.DragEvent) => {
    setDropPosition('none');
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    const { id } = JSON.parse(e.dataTransfer.getData('text'));
    props.onChange?.(id, props.id, dropPosition === 'above');
    setDropPosition('none');
  };

  const dropStyle: CSSProperties =
    dropPosition === 'none'
      ? {}
      : dropPosition === 'above'
        ? { borderTopColor: '#555' }
        : { borderBottomColor: '#555' };

  return (
    <div
      className="flex items-center border-y border-transparent py-1"
      style={dropStyle}
      onDragOver={handleDragOver.bind(this)}
      onDragLeave={handleDragLeave.bind(this)}
      onDrop={handleDrop?.bind(this)}
    >
      <span
        className="material-icons cursor-pointer opacity-20 transition-opacity hover:opacity-60"
        draggable
        onDragStart={handleDragStart.bind(this)}
        onDragEnd={handleDragEnd.bind(this)}
      >
        drag_indicator
      </span>
      {props.children}
    </div>
  );
};

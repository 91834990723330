import { IconButton } from '../buttons';

export interface SettingsTableColSpec {
  label: string;
  key?: string;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
}

export interface SettingsTableProps {
  label: string;
  meta?: React.ReactNode;
  children: React.ReactNode;
  columns?: SettingsTableColSpec[];
  onAdd?: (e: React.MouseEvent) => void;
}

/**
 * This provides a tbody wrapper to ensure that the look and feel of the table is consistent.
 */
export const SettingsTable = (props: SettingsTableProps) => {
  return (
    <div>
      <div className="label flex items-center">
        <span className="flex-1 text-sm font-medium">{props.label}</span>
        {props.meta}
      </div>
      <div className="border-silver-200 relative min-h-44 rounded border bg-white p-2 pt-3">
        <table className="mb-8 w-full border-separate border-spacing-y-1">
          {props.columns && (
            <thead className="text-left text-xs">
              <tr>
                {props.columns.map((column, index) => (
                  <th
                    key={index}
                    style={{
                      minWidth: column.minWidth,
                      width: column.width,
                      maxWidth: column.maxWidth
                    }}
                  >
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
          )}
          <tbody>{props.children}</tbody>
        </table>
        <div className="absolute bottom-0 right-1">
          <IconButton color="ghost" onClick={props.onAdd?.bind(this)}>
            add
          </IconButton>
        </div>
      </div>
    </div>
  );
};

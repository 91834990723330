import {
  Dialog,
  DialogContext,
  Progress,
  SplitButton
} from '@kidsmanager/ui-core';
import { PremiumsSettings } from './premiums-settings';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import {
  IExportColumn,
  IPremiumMap,
  IRosterTemplate
} from '@kidsmanager/util-models';
import {
  compileReport as compileReportData,
  initialiseSettings,
  InternalPremiumMap,
  packSettings,
  PremiumsByCostCenter
} from './premiums-helpers';

const sources = [
  { key: 'month', label: 'Monat' },
  { key: 'year', label: 'Jahr' },
  { key: 'day', label: 'Tag' },
  { key: 'employeenumber', label: 'Mitarbeiternummer' },
  { key: 'amount', label: 'Menge' },
  { key: 'costcenter', label: 'Kostenstelle' },
  { key: 'code', label: 'Lohnart' }
];

export const Premiums = () => {
  const { t, i18n } = useTranslation('reports');
  const dialog = useContext(DialogContext);
  const client = useContext(ClientBackendContext);
  const params = useParams<{ month: string }>();
  const [templates, setTemplates] = useState<IRosterTemplate[]>([]);
  const [exportColumns, setExportColumns] = useState<IExportColumn[]>([]);
  const [premiumsMap, setPremiumsMap] = useState<InternalPremiumMap[]>();
  const [data, setData] = useState<PremiumsByCostCenter[]>();
  const month = useMemo(
    () => (params.month ? new Date(params.month) : new Date()),
    [params]
  );

  useEffect(() => {
    Promise.all([
      client.admin.roster.templates.find(),
      client.report.settings('timesheet-premiums'),
      client.report.timesheet.premiums(month)
    ]).then(([templates, settings, data]) => {
      const { exportColumns, premiumsMap } = initialiseSettings(
        templates,
        settings,
        t
      );
      setTemplates(templates);
      setExportColumns(exportColumns);
      setPremiumsMap(premiumsMap);
    });
  }, [client, month, t]);

  useEffect(() => {
    if (!premiumsMap) return;
    client.report.timesheet.premiums(month).then((data) => {
      setData(compileReportData(data, premiumsMap, t));
    });
  }, [client, premiumsMap, t, month]);

  const handleSave = async (settings: {
    columns: IExportColumn[];
    map: IPremiumMap[];
  }) => {
    const value = packSettings(settings.columns, settings.map);
    await client.report.saveSettings('timesheet-premiums', value);
    const updated = initialiseSettings(templates, value, t);
    setExportColumns(updated.exportColumns);
    setPremiumsMap(updated.premiumsMap);
  };

  const handleExport = (key: string | undefined) => {
    if (!premiumsMap) return;

    switch (key) {
      case 'zip':
        console.log('Export zip');
        break;
      case 'settings':
        dialog.open(
          <Dialog
            title={t('timesheet.premiums.settings_title')}
            width="w-[750px]"
            onClose={() => dialog.close()}
          >
            <PremiumsSettings
              templates={templates}
              sources={sources}
              map={premiumsMap}
              exportColumns={exportColumns}
              onCancel={() => dialog.close()}
              onSave={handleSave.bind(this)}
            />
          </Dialog>
        );
        break;
      default:
        console.log('Export all');
        break;
    }
  };

  return (
    <section className="p-4">
      <div className="mb-4 flex">
        <h1 className="flex flex-1 gap-1.5 text-xl">
          <span>{t('timesheet.premiums.title')}</span>
          <span>&gt;</span>
          <span>
            {month.toLocaleString(i18n.language, {
              month: 'long',
              year: 'numeric'
            })}
          </span>
        </h1>
        <div className="flex space-x-2">
          <SplitButton
            options={[
              { key: 'all', label: t('timesheet.premiums.export_all') },
              { key: 'zip', label: t('timesheet.premiums.export_zip') },
              {
                key: 'settings',
                label: t('timesheet.premiums.settings'),
                disabled: !client.auth.inRole('admin')
              }
            ]}
            onClick={handleExport.bind(this)}
          >
            <span className="px-2">{t('common.export')}</span>
          </SplitButton>
        </div>
      </div>
      <div>
        {data ? (
          data.map((group) => (
            <table key={group.costcenter} className="mb-6 w-full max-w-2xl">
              <thead>
                <tr>
                  <th className="pb-2 text-left">{`Kostenstelle ${group.costcenter}`}</th>
                  <th className="pb-2 text-left">Beschreibung</th>
                  <th className="pb-2 text-left">Lohnart</th>
                  <th className="pb-2 text-left">Menge</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {group.values.map((item, index) => (
                  <tr key={index} className="text-sm">
                    <td className="pl-6">{item.name}</td>
                    <td className="opacity-50">{item.label}</td>
                    <td className="pr-4 text-right">{item.code}</td>
                    <td className="pr-4 text-right">{item.value}</td>
                    <td className="opacity-50">{item.unit}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ))
        ) : (
          <div className="mx-auto max-w-lg pt-10 text-center text-sm">
            <Progress mode="indeterminate" />
            <p className="mt-2 opacity-50">Daten analysieren...</p>
          </div>
        )}
      </div>
    </section>
  );
};

import { useEffect, useMemo } from 'react';
import { Button } from '../../buttons';

export interface DialogErrorProps {
  children: string;
  onClose?: () => void;
}

export const DialogError = (props: DialogErrorProps) => {
  const lines = useMemo(() => {
    return props.children
      .split('\n')
      .map((row, index) => <div key={index}>{row}</div>);
  }, [props]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.key === 'Enter') {
        props.onClose?.();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [props]);

  return (
    <div className="relative min-h-48 w-full max-w-lg bg-white p-10 pb-20">
      <div>
        <span
          className="material-icons text-error"
          style={{ fontSize: '40px' }}
        >
          error
        </span>
      </div>
      <div className="flex flex-col gap-2 pl-4">{lines}</div>
      <div className="absolute inset-x-4 bottom-4 flex justify-end gap-2">
        <Button onClick={props.onClose}>OK</Button>
      </div>
    </div>
  );
};

import { IYearAdjustments } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiTimesheetAdjustments {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async all(...years: number[]): Promise<IYearAdjustments[]> {
    years.sort();
    return cache.get(`all-adjustments-${years.join('-')}`, async () =>
      (
        await this.fetch(
          `/api/timesheet/adjustments?${years.map((y) => `year=${y}`).join('&')}`
        )
      ).json()
    );
  }

  async save(
    year: number,
    adjustments: IYearAdjustments[]
  ): Promise<void | unknown> {
    if (adjustments.length === 0) {
      return;
    }
    return this.fetch(`/api/timesheet/adjustments?year=${year}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(adjustments)
    });
  }
}

export interface DialogDesktopContainerProps {
  close?: () => void;
  children: React.ReactNode;
}

export const DialogDesktopContainer = (props: DialogDesktopContainerProps) => {
  return (
    <div
      className="fixed bottom-0 left-0 right-0 top-0 z-40 bg-black/60"
      onClick={props.close?.bind(this)}
    >
      <div className="-mt-16 flex h-full items-center justify-center">
        <div onClick={(e) => e.stopPropagation()}>{props.children}</div>
      </div>
    </div>
  );
};

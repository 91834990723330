import { DialogHdr } from './dialog-hdr/dialog-hdr';

export interface DialogProps {
  title?: string;
  children: React.ReactNode;
  width?: string;
  onClose?: () => void;
}

export const Dialog = (props: DialogProps) => {
  const width = props.width || 'max-w-xl';
  return (
    <div
      role="dialog"
      className={`xs:rounded-lg relative ${width} animate-dialog mx-auto max-h-[92vh] overflow-hidden rounded-t-xl bg-white p-4`}
      style={{ maxWidth: 'calc(100vw - 2rem)' }}
    >
      <DialogHdr close={props.onClose}>{props.title}</DialogHdr>
      <div className={`xs:min-w-[440px] w-full`}>{props.children}</div>
    </div>
  );
};

import { RuleSpec } from './rule-spec';

export const ruleSpecsOther: RuleSpec[] = [
  {
    id: 'accounting-period',
    category: 'other',
    defaults: [
      { key: 'from', type: 'day', value: '01.08' },
      { key: 'to', type: 'day', value: '31:07' }
    ],
    values: [],
    enabled: false
  },
  {
    id: 'avaerge-hours-over-year',
    category: 'other',
    defaults: [{ key: 'average', type: 'number', value: '38' }],
    values: [],
    enabled: false
  },
  {
    id: 'name-before-x',
    category: 'other',
    defaults: [
      { key: 'label', type: 'from', value: 'nightshift-required:name' },
      { key: 'name', type: 'label', value: '' },
      { key: 'time', type: 'time', value: '12:00' }
    ],
    values: [],
    enabled: false
  },
  {
    id: 'name-after-x',
    category: 'other',
    defaults: [
      { key: 'label', type: 'from', value: 'nightshift-required:name' },
      { key: 'name', type: 'label', value: '' },
      { key: 'time', type: 'time', value: '12:00' }
    ],
    values: [],
    enabled: false
  },
  {
    id: 'auto-public-hols',
    category: 'other',
    defaults: [],
    values: [],
    enabled: false
  }
];

import { Checkbox, LabelRotated } from '@kidsmanager/ui-core';
import { IRole, ITemplateSummary, ROLE_LABELS } from '@kidsmanager/util-models';
import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { OutletContextAdminGroups } from './outlet-context-admin-groups';
import { ClientBackendContext } from '@kidsmanager/ui-api';

export type Section = 'view' | 'add' | 'archive';
const sections: Section[] = ['view', 'add'];

export const AdminGroupTemplates = () => {
  const context = useOutletContext<OutletContextAdminGroups>();
  const client = useContext(ClientBackendContext);
  const [templates, setTemplates] = useState<ITemplateSummary[]>([]);
  const { t } = useTranslation('admin');
  const { selected } = context;
  const readonly = true;

  const roles = useMemo(
    () => Array.from(ROLE_LABELS.keys()).filter((x) => x !== 'admin'),
    []
  );

  useEffect(() => {
    client.admin.template
      .summary()
      .then((x) => setTemplates(x.sort((a, b) => (a.name > b.name ? 1 : -1))));
  }, [client]);

  const roleContainsTemplate = (
    section: Section,
    role: IRole,
    templateId: string
  ): boolean | undefined => {
    switch (section) {
      case 'view':
        return selected.templates.entity.view[role].includes(templateId);
      case 'add':
        return selected.templates.entity.add[role].includes(templateId);
      case 'archive':
        return selected.templates.archive.view[role].includes(templateId);
    }
  };

  const handleChecked = (
    section: Section,
    role: IRole,
    templateId: string,
    value: boolean
  ) => {
    switch (section) {
      case 'view':
        selected.templates.entity.view[role] = value
          ? [...selected.templates.entity.view[role], templateId]
          : selected.templates.entity.view[role].filter(
              (r) => r !== templateId
            );
        break;
      case 'add':
        selected.templates.entity.add[role] = value
          ? [...selected.templates.entity.add[role], templateId]
          : selected.templates.entity.add[role].filter((r) => r !== templateId);
        break;
      case 'archive':
        selected.templates.archive.view[role] = value
          ? [...selected.templates.archive.view[role], templateId]
          : selected.templates.archive.view[role].filter(
              (r) => r !== templateId
            );
        break;
    }
    context.onChange?.();
  };

  return !selected ? undefined : (
    <>
      {readonly && (
        <div className="my-6 max-w-xl rounded-md border border-black/5 bg-yellow-100/80 px-6 py-2 text-sm">
          {t('groups.templates.readonly')}
        </div>
      )}
      <div
        key={selected.id}
        className="mb-10 grid gap-2"
        style={{
          gridTemplateColumns: `170px repeat(${templates.length}, minmax(0, 3rem))`
        }}
      >
        {sections.map((section) => (
          <Fragment key={section}>
            <div className={`flex h-28 items-end font-semibold`}>
              {t(`groups.templates.${section}`)}
            </div>
            {templates.map((template, index) => (
              <div key={index} className="relative text-nowrap text-sm">
                <LabelRotated
                  maxWidth="8rem"
                  tooltip={`${template.groupName} > ${template.name}`}
                  rotate
                >
                  {template.name}
                </LabelRotated>
              </div>
            ))}
            {roles.map((role, roleIndex) => (
              <Fragment key={roleIndex}>
                <label className="pl-4">{ROLE_LABELS.get(role)}</label>
                {templates.map(({ id }) => (
                  <Checkbox
                    key={id}
                    disabled={readonly}
                    checked={roleContainsTemplate(section, role, id)}
                    onChange={handleChecked.bind(this, section, role, id)}
                  />
                ))}
              </Fragment>
            ))}
          </Fragment>
        ))}
      </div>
    </>
  );
};

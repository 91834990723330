import { Checkbox, Input } from '@kidsmanager/ui-core';
import { IRosterRuleValue } from '@kidsmanager/util-models';
import { RuleSpec } from '@kidsmanager/util-rules';
import { useEffect, useId, useRef, useState } from 'react';

export interface ShiftRuleProps {
  description: string;
  values: IRosterRuleValue[];
  context: Map<string, RuleSpec[]>;
  enabled: boolean;
  onChange?: (values: IRosterRuleValue[], enabled: boolean) => void;
}

interface ShiftRulePart {
  key: string;
  type: string;
  value: string;
}

export const ShiftRule = (props: ShiftRuleProps) => {
  const [parts, setParts] = useState<ShiftRulePart[]>([]);
  const form = useRef<HTMLFormElement>(null);
  const id = useId();

  useEffect(() => {
    const allSpecs = Array.from(props.context.values()).flatMap((x) => x);
    setParts(
      props.description.split(/({{.*?}})/).map((part) => {
        if (part.startsWith('{{') && part.endsWith('}}')) {
          const [key, fallback] = part.slice(2, -2).split(':');
          const val = props.values.find((v) => v.key === key);
          if (!val) {
            return { key, type: 'text', value: fallback || key };
          } else if (val?.type === 'from') {
            const [specId, propKey] = val.value.includes(':')
              ? val.value.split(':')
              : [val.value, key];
            const found = allSpecs.find((s) => s.id === specId);
            const valFrom = found?.values.find((v) => v.key === propKey)?.value;
            return { key, type: 'from', value: valFrom || fallback };
          } else {
            return { key, type: val.type, value: val.value || fallback || key };
          }
        } else {
          return { key: '', type: 'text', value: part };
        }
      })
    );
  }, [props]);

  const handleChange = () => {
    if (!form.current) {
      return;
    }
    const data = new FormData(form.current);
    const updated: IRosterRuleValue[] = [];
    const enabled = data.get('enabled') === 'on';
    props.values.forEach((v) => {
      const value = data.get(v.key);
      updated.push({ ...v, value: value?.toString() || '' });
    });
    props.onChange?.(updated, enabled);
  };

  return (
    <form
      ref={form}
      className="flex items-start p-2 opacity-60 has-[:checked]:opacity-100"
      onBlur={handleChange.bind(this)}
    >
      <div className="mt-0.5 pt-2">
        <Checkbox
          id={id}
          name="enabled"
          defaultChecked={props.enabled}
          onClick={handleChange.bind(this)}
        />
      </div>
      <div className="leading-[38px]">
        {parts.map((part, index) => (
          <span key={index}>
            {part.type === 'text' && (
              <label
                className="cursor-pointer"
                style={{ userSelect: 'none' }}
                htmlFor={id}
              >
                {part.value}
              </label>
            )}
            {part.type === 'number' && (
              <span className="mr-0.5 inline-block w-12">
                <Input
                  name={part.key}
                  mask="number"
                  min={0}
                  max={99}
                  defaultValue={part.value}
                />
              </span>
            )}
            {part.type === 'time' && (
              <span className="mr-0.5 inline-block w-16">
                <Input name={part.key} mask="time" defaultValue={part.value} />
              </span>
            )}
            {part.type === 'day' && (
              <span className="mr-0.5 inline-block w-16">
                <Input name={part.key} mask="day" defaultValue={part.value} />
              </span>
            )}
            {part.type === 'label' && (
              <span
                className="mr-0.5 inline-block w-20"
                style={{ minWidth: `${part.value.length + 1}ch` }}
              >
                <Input name={part.key} defaultValue={part.value} />
              </span>
            )}
            {part.type === 'from' && (
              <span className="mr-0.5">{part.value}</span>
            )}
          </span>
        ))}
        <span>.</span>
      </div>
    </form>
  );
};

import { RuleSpec } from './rule-spec';

export const ruleSpecsSickLeave: RuleSpec[] = [
  {
    id: 'sickleave-as-planned',
    category: 'sickleave',
    defaults: [{ key: 'days', type: 'number', value: '8' }],
    values: [],
    enabled: true
  },
  {
    id: 'sickleave-doctor-note',
    category: 'sickleave',
    defaults: [{ key: 'days', type: 'number', value: '1' }],
    values: [],
    enabled: true
  }
];

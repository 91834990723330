import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { IUser } from '@kidsmanager/util-models';
import {
  Input,
  Button,
  DialogContext,
  DialogConfirmUnsaved
} from '@kidsmanager/ui-core';
import {
  AdjustmentMap,
  buildMatrix,
  calcTotals,
  expandAdjustments
} from './adjustment-helpers';

export const Adjustment = () => {
  const { t } = useTranslation('reports');
  const client = useContext(ClientBackendContext);
  const navigate = useNavigate();
  const dialog = useContext(DialogContext);
  const [params] = useSearchParams({
    type: 'hours',
    year: `${new Date().getFullYear()}`
  });
  const monthOffset = -5;
  const [users, setUsers] = useState<(IUser & { isDirty: boolean })[]>([]);
  const [adjustments, setAdjustments] = useState<AdjustmentMap>([]);
  const [totals, setTotals] = useState<number[]>([]);
  const [matrix, setMatrix] = useState<string[][]>([]);
  const [highlight, setHighlight] = useState<IUser>();
  const [isDirty, setIsDirty] = useState(false);

  const tab = useMemo<'hours' | 'holidays'>(
    () => (params.get('type') === 'hours' ? 'hours' : 'holidays'),
    [params]
  );

  useEffect(() => {
    const id = params.get('id');
    setTimeout(() => {
      if (!id) {
        return;
      }
      const el = document.getElementById(id);
      if (el) {
        el.scrollIntoView({ behavior: 'instant', block: 'center' });
      }
    }, 200);
    setHighlight(users.find((user) => user.userId === id));
  }, [users, params]);

  const years = useMemo(() => {
    const current = Number(params.get('year')) || new Date().getFullYear();
    return [current - 1, current];
  }, [params]);

  const months = useMemo(() => {
    const offset = tab === 'hours' ? monthOffset : 0;
    return Array.from(
      { length: 12 },
      (_, i) => new Date(years[1], i + offset, 1)
    );
  }, [years, monthOffset, tab]);

  useEffect(() => {
    Promise.all([
      client.admin.users.active(),
      client.timesheet.adjustments.all(...years)
    ]).then(([allUsers, storedAdj]) => {
      setUsers(allUsers.map((user) => ({ ...user, isDirty: false })));
      const expandedAdjustments = expandAdjustments(allUsers, years, storedAdj);
      const newMatrix = buildMatrix(allUsers, years, expandedAdjustments, tab);
      setAdjustments(expandedAdjustments);
      setMatrix(newMatrix);
      setTotals(calcTotals(allUsers, newMatrix, tab));
    });
  }, [client, tab, years]);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      const inputs = Array.from(document.querySelectorAll('input'));
      const index = Array.from(inputs).findIndex(
        (input) => input === document.activeElement
      );
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        inputs.at(index - 12)?.focus();
      }
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        inputs.at(index + 12)?.focus();
      }
    };
    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, []);

  const getAllowance = (user: IUser, type: string) => {
    return type === 'hours' ? user.hoursPerWeek : user.holidayAllowance;
  };

  const setValue = (userIndex: number, month: number, value: string) => {
    const parsed = parseFloat(value);
    if (value && value !== '-' && isNaN(parsed)) {
      return;
    }
    if (value && parsed < -999) {
      value = '-999';
    }
    if (value && parsed > 999) {
      value = '999';
    }

    setMatrix((prev) => {
      const next = structuredClone(prev);
      next[userIndex][month] = value;
      users[userIndex].isDirty = true;
      setTotals(calcTotals(users, next, tab));
      setIsDirty(true);
      return next;
    });
  };

  const executeSwitchTab = (type: 'hours' | 'holidays') => {
    switch (type) {
      case 'hours':
        navigate(
          `/report/timesheet/adjustment?year=${years[1]}&type=hours&id=${highlight?.userId}`
        );
        break;
      case 'holidays':
        navigate(
          `/report/timesheet/adjustment?year=${years[1]}&type=holidays&id=${highlight?.userId}`
        );
        break;
    }
  };

  const handleSwitchTab = (type: 'hours' | 'holidays') => {
    if (isDirty) {
      dialog.open(
        <DialogConfirmUnsaved
          onCancel={() => dialog.close()}
          onConfirm={() => {
            dialog.close();
            handleCancel();
            executeSwitchTab(type);
          }}
        ></DialogConfirmUnsaved>
      );
    } else {
      executeSwitchTab(type);
    }
  };

  const handleUserClicked = (user: IUser) => {
    const selected = highlight?.userId === user.userId;
    if (selected && isDirty) {
      dialog.open(
        <DialogConfirmUnsaved
          onCancel={() => dialog.close()}
          onConfirm={() => {
            dialog.close();
            navigate(`/admin/users/${user.userId}/shifts`);
          }}
        ></DialogConfirmUnsaved>
      );
    } else if (selected) {
      navigate(`/admin/users/${user.userId}/shifts`);
    } else {
      setHighlight(user);
    }
  };

  const handleSave = async () => {
    const changes: AdjustmentMap = {};
    years.forEach((year) => (changes[year] = []));

    for (const [userIndex, user] of users.entries()) {
      if (!user.isDirty) {
        continue;
      }
      if (tab === 'holidays') {
        adjustments[years[1]][userIndex].holidays = matrix[userIndex].map(
          (v) => parseFloat(v) || 0
        );
        changes[years[1]].push(adjustments[years[1]][userIndex]);
      } else {
        //for hours
      }
    }
    await Promise.all(
      years.map((y) => client.timesheet.adjustments.save(y, changes[y]))
    );
    setIsDirty(false);
  };

  const handleCancel = () => {
    const newMatrix = buildMatrix(users, years, adjustments, tab);
    setMatrix(newMatrix);
    setTotals(calcTotals(users, newMatrix, tab));
    setIsDirty(false);
  };

  return (
    <section className="p-4">
      <div className="flex">
        <h1 className="flex-1 text-xl">
          {t('timesheet.adjustment.title')} &gt;{' '}
          {`${years[0]}/${years[1] - 2000}`}
        </h1>
        <div className="flex space-x-2">
          <Button disabled={!isDirty} onClick={handleCancel.bind(this)}>
            {t('common.cancel')}
          </Button>
          <Button
            disabled={!isDirty}
            color="accent"
            onClick={handleSave.bind(this)}
          >
            {t('common.save')}
          </Button>
        </div>
      </div>
      <div className="my-4 flex">
        <div className="min-w-28 pt-2">
          <div
            onClick={handleSwitchTab.bind(this, 'hours')}
            className={`block cursor-pointer rounded-l-md pl-2 leading-8 ${tab === 'hours' && 'bg-neutral-300 font-semibold'}`}
          >
            {t('timesheet.adjustment.hours')}
          </div>
          <div
            onClick={handleSwitchTab.bind(this, 'holidays')}
            className={`block cursor-pointer rounded-l-md pl-2 leading-8 ${tab === 'holidays' && 'bg-neutral-300 font-semibold'}`}
          >
            {t('timesheet.adjustment.holidays')}
          </div>
        </div>
        <div className="max-h-[80vh] min-h-[80vh] flex-1 overflow-y-scroll rounded-md border border-neutral-300 px-2">
          <table className="w-full max-w-5xl">
            <thead className="sticky top-0 z-10 bg-white/80 backdrop-blur-md">
              <tr>
                <th className="min-w-40 pt-4"></th>
                <th className="w-20 pt-4 text-left align-bottom">
                  <div>{t('timesheet.adjustment.allowance')}</div>
                  <div className="text-xs font-normal">
                    {t(`timesheet.adjustment.allowance_${tab}`)}
                  </div>
                </th>
                {months.map((month, i) => (
                  <th className="min-w-10 pt-4 align-bottom" key={i}>
                    {i === 0 || month.getMonth() === 0 ? (
                      <div>'{month.getFullYear() - 2000}</div>
                    ) : undefined}
                    {month.toLocaleDateString('de-DE', { month: 'short' })}
                  </th>
                ))}
                <th className="pt-4 align-bottom">Gesamt</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, userIndex) => (
                <tr
                  key={`${user.userId}-${tab}`}
                  id={user.userId}
                  style={{
                    backgroundColor:
                      highlight?.userId === user.userId
                        ? 'rgba(0, 0, 0, 0.05)'
                        : 'inherit'
                  }}
                >
                  <td
                    onClick={handleUserClicked.bind(this, user)}
                    className="cursor-pointer"
                    style={{
                      textDecoration:
                        highlight?.userId === user.userId ? 'underline' : 'none'
                    }}
                  >
                    {user.lastName}, {user.firstName}
                  </td>
                  <td className="pr-2 text-right">{getAllowance(user, tab)}</td>
                  {months.map((_, i) => (
                    <td className="p-1" key={i}>
                      <Input
                        mask="number"
                        value={matrix[userIndex][i]}
                        onChange={setValue.bind(this, userIndex, i)}
                      />
                    </td>
                  ))}
                  <td className="pr-2 text-right">{totals[userIndex]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

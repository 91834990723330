import React, { useContext } from 'react';
type SetContentFunc = (value: React.SetStateAction<React.ReactNode>) => void;

export interface ISnackbar {
  open(content: React.ReactNode): void;
  close(): void;
}

export class SnackbarControl implements ISnackbar {
  private _bind: SetContentFunc | undefined = undefined;

  open(content: React.ReactNode) {
    this._bind?.(content);
  }
  close(): void {
    this._bind?.(undefined);
  }
  bind(x: SetContentFunc) {
    this._bind = x;
  }
}

export interface SnackbarProviderProps {
  children: React.ReactNode;
}

export const SnackbarContext = React.createContext(
  new SnackbarControl() as ISnackbar
);

export const SnackbarProvider = (props: SnackbarProviderProps) => {
  const [content, setContent] = React.useState<React.ReactNode>();
  const [timer, setTimer] = React.useState<NodeJS.Timeout>();

  const handleSet = (value: React.SetStateAction<React.ReactNode>) => {
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(setTimeout(() => setContent(undefined), 2000));
    setContent(value);
  };

  (useContext(SnackbarContext) as SnackbarControl).bind(handleSet);
  return (
    <>
      {props.children}
      <div
        id="snackbar"
        className="fixed bottom-0 left-4 right-4 z-50 md:left-64"
      >
        {content}
      </div>
    </>
  );
};

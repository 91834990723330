import { useEffect, useState } from 'react';

export interface ToggleButtonProps {
  children: React.ReactNode;
  active?: boolean;
  disabled?: boolean;
  onChange?: (state: boolean) => void;
  className?: string;
  color?: 'lime' | 'primary';
}

export function ToggleButton(props: ToggleButtonProps) {
  const [active, setActive] = useState(false);
  const color = props.color || 'lime';

  useEffect(() => {
    setActive(!!props.active);
  }, [props.active]);

  const handleClick = () => {
    if (props.onChange) {
      props.onChange(!active);
    } else {
      setActive((prev) => !prev);
    }
  };

  const getColor = () => {
    if (color === 'lime') {
      if (active) {
        return props.disabled
          ? 'bg-lime-500 text-white'
          : 'bg-lime-500 text-white active:bg-lime-300';
      } else {
        return props.disabled
          ? 'bg-silver-200 text-black/50'
          : 'text-silver-950 hover:bg-silver-200';
      }
    } else if ((active || props.disabled) && color === 'primary') {
      return 'bg-primary text-contrast';
    }
    return 'text-silver-950 hover:bg-silver-200';
  };

  return (
    <button
      onClick={handleClick}
      disabled={props.disabled}
      className={`border-silver-400 outline-focus rounded-md border px-2 py-1 text-sm outline-offset-4 ${props.className} ${getColor()}`}
      style={{ opacity: props.disabled ? 0.5 : 1 }}
    >
      {props.children}
    </button>
  );
}

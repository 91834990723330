import { IUser, IYearAdjustments } from '@kidsmanager/util-models';

export type AdjustmentMap = { [year: number]: IYearAdjustments[] };

export const expandAdjustments = (
  users: IUser[],
  years: number[],
  adjustments: IYearAdjustments[]
): AdjustmentMap => {
  const map: AdjustmentMap = {};
  for (const year of years) {
    const yearAdjustments: IYearAdjustments[] = [];
    for (const user of users) {
      const match = adjustments.find(
        (a) => a.userId === user.userId && a.year === year
      );
      if (match) {
        yearAdjustments.push({ ...match });
      } else {
        yearAdjustments.push({
          userId: user.userId,
          year,
          hours: Array.from({ length: 12 }, () => 0),
          holidays: Array.from({ length: 12 }, () => 0)
        });
      }
    }
    map[year] = yearAdjustments;
  }
  return map;
};

export const calcTotals = (
  users: IUser[],
  matrix: string[][],
  type: string
): number[] => {
  return users.map((user, i) => {
    const sum = matrix[i].reduce((sum, cell) => {
      const val = parseFloat(cell);
      return sum + (isNaN(val) ? 0 : val);
    }, 0);
    switch (type) {
      case 'hours':
        return sum;
      case 'holidays':
        return (user.holidayAllowance || 0) + sum;
      default:
        return 0;
    }
  });
};

export const buildMatrix = (
  users: IUser[],
  years: number[],
  adjustments: AdjustmentMap,
  type: 'hours' | 'holidays'
): string[][] => {
  switch (type) {
    case 'hours':
      return users.map((user) => {
        return Array.from({ length: 12 }, () => '');
      });
    case 'holidays':
      return users.map((_, i) =>
        adjustments[years[1]][i].holidays.map((x) =>
          x === 0 ? '' : x.toString()
        )
      );
  }
};

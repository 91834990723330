import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';
import { IWeek, IMonth, IDay, IUserAllowance } from '@kidsmanager/util-models';
import { hoursForMonth, IHoursForMonth } from './api-timesheet-helper';
import { ApiTimesheetLog } from './api-timesheet-log';
import { ApiTimesheetApprovals } from './api-timesheet-approvals';
import { ApiTimesheetAdjustments } from './api-timesheet-adjustments';
import { ApiTimesheetAbsences } from './api-timesheet-absences';

const cache = new BackendCache();

export class ApiTimesheet {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {
    this.log = new ApiTimesheetLog(auth, fetch, (key) => cache.clear(key));
    this.approvals = new ApiTimesheetApprovals(auth, fetch);
    this.adjustments = new ApiTimesheetAdjustments(auth, fetch);
    this.absences = new ApiTimesheetAbsences(auth, fetch);
  }

  log: ApiTimesheetLog;
  approvals: ApiTimesheetApprovals;
  adjustments: ApiTimesheetAdjustments;
  absences: ApiTimesheetAbsences;

  async week(date: Date, userId?: string): Promise<IWeek> {
    userId = userId || 'my';
    const week = date.getWeek();
    return cache.get(`week-${userId}-${week.year}-${week.num}`, async () => {
      return (
        await this.fetch(
          `/api/timesheet/${userId}/week?year=${week.year}&week=${week.num}`
        )
      ).json();
    });
  }

  async month(year: number, month: number, userId?: string): Promise<IMonth> {
    userId = userId || 'my';
    return cache.get(`month-${userId}-${year}-${month}`, async () => {
      return (
        await this.fetch(
          `/api/timesheet/${userId}/logged?month=${year}-${month}`
        )
      ).json();
    });
  }

  async days(year: number, month: number, userId?: string): Promise<IDay[]> {
    userId = userId || 'my';
    return cache.get(`days-${userId}-${year}-${month}`, async () => {
      return (
        await this.fetch(`/api/timesheet/${userId}/days?month=${year}-${month}`)
      ).json();
    });
  }

  async contracted(userId?: string): Promise<IUserAllowance[]> {
    userId = userId || 'my';
    return cache.get(`contracted-${userId}`, async () => {
      const values = (await (
        await this.fetch(`/api/timesheet/${userId}/contracted`)
      ).json()) as IUserAllowance[];
      values.sort((a, b) => (a.from > b.from ? 1 : -1));
      return values;
    });
  }

  async contractedFor(
    year: number,
    month: number,
    userId?: string
  ): Promise<IHoursForMonth> {
    const allowances = await this.contracted(userId);
    return hoursForMonth(allowances, year, month);
  }
}

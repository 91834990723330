import { IAddress, ITrip } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiExpenses {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async summaries(userId?: string) {
    userId = userId || 'my';
    const key = `summary-${userId}`;
    return cache.get(key, async () =>
      (await this.fetch(`api/expenses/${userId}/summary?type=trip`)).json()
    );
  }

  async tripsFor(
    year: number,
    month: number,
    userId?: string
  ): Promise<ITrip[]> {
    userId = userId || 'my';
    const monthStr = `${year}-${month.toString().padStart(2, '0')}`;
    const key = `trips-${userId}-${monthStr}`;
    return cache.get(key, async () =>
      (await this.fetch(`api/expenses/${userId}/trip?month=${monthStr}`)).json()
    );
  }

  async trip(
    year: number,
    month: number,
    userId: string,
    id: string
  ): Promise<ITrip | undefined> {
    const trips = await this.tripsFor(year, month, userId);
    return trips.find((trip) => trip.id === id);
  }

  async addTrip(trip: ITrip, userId?: string) {
    userId = userId || 'my';
    cache.clear(`trips-${userId}-*`);
    cache.clear(`summary-${userId}`);
    return this.fetch(`api/expenses/${userId}/trip`, {
      method: 'POST',
      body: JSON.stringify(trip)
    });
  }

  async deleteTrip(year: number, month: number, userId: string, id: string) {
    cache.clear(`trips-${userId}-*`);
    cache.clear(`summary-${userId}`);
    return this.fetch(`api/expenses/${userId}/trip/${year}-${month}/${id}`, {
      method: 'DELETE'
    });
  }

  async addresses(pattern: string, userId?: string): Promise<IAddress[]> {
    userId = userId || 'my';
    const key = `addresses-${userId}-${pattern}`;
    return cache.get(
      key,
      async () =>
        (
          await this.fetch(`api/expenses/${userId}/address?pattern=${pattern}`)
        ).json(),
      { drop: 'addresses-*' }
    );
  }

  async updateAddress(address: string, userId?: string) {
    userId = userId || 'my';
    cache.clear(`addresses-${userId}-*`);
    return this.fetch(`api/expenses/${userId}/address`, {
      method: 'PUT',
      body: JSON.stringify({ address })
    });
  }

  async deleteAddress(id: string, userId?: string) {
    userId = userId || 'my';
    cache.clear(`addresses-${userId}-*`);
    return this.fetch(`api/expenses/${userId}/address/${id}`, {
      method: 'DELETE'
    });
  }
}

import { ITimesheetPremium } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiReportTimesheet {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async premiums(month: Date): Promise<ITimesheetPremium> {
    const monthStr = month.toLocaleISOMonth();
    return cache.get(`premiums-${monthStr}`, async () =>
      (
        await this.fetch(`/api/report/timesheet/premiums?month=${monthStr}`)
      ).json()
    );
  }
}

import { IReportSettings } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';
import { ApiReportTimesheet } from './api-report-timesheets';

const cache = new BackendCache();

export class ApiReport {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {
    this.timesheet = new ApiReportTimesheet(this.auth, this.fetch.bind(this));
  }

  timesheet: ApiReportTimesheet;

  async settings(report: string): Promise<IReportSettings> {
    return cache.get(`settings-${report}`, async () =>
      (await this.fetch(`/api/report/settings?report=${report}`)).json()
    );
  }

  async saveSettings(report: string, settings: IReportSettings): Promise<void> {
    await this.fetch(`/api/report/settings?report=${report}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(settings)
    });
    cache.clear(`settings-${report}`);
  }
}

import { useContext, useEffect, useMemo } from 'react';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { Dialog, DialogContext, LinkButton } from '@kidsmanager/ui-core';
import { InternalShiftSpec } from './models';
import { ShiftEditor, ShiftSpec } from './components';

export interface RosterShiftManagerProps {
  group: string;
  year: number;
  month: number;
  specs: InternalShiftSpec[];
  onSelected?: (spec: InternalShiftSpec) => void;
  onChange?: (spec: InternalShiftSpec[]) => void;
}

export const RosterShiftManager = (props: RosterShiftManagerProps) => {
  const dialog = useContext(DialogContext);
  const client = useContext(ClientBackendContext);

  const specs = useMemo(
    () => props.specs.filter((x) => !x.groupId || x.groupId === props.group),
    [props.specs, props.group]
  );

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if ((e.target as HTMLElement).closest('[role="dialog"]')) {
        return;
      }
      e.preventDefault();
      if (e.code === 'Space') {
        if (e.shiftKey) {
          const index = specs.findIndex((x) => x.selected);
          const next = index >= specs.length - 1 ? 2 : index + 1;
          props.onSelected?.(specs[next]);
        } else {
          //specs starting with the same letter symbol, but not currently selected
          const current = specs.find((x) => x.selected)?.symbol?.toLowerCase();
          if (!current) return;
          const next = specs.find(
            (x) => x.symbol?.toLowerCase().at(0) === current[0] && !x.selected
          );
          if (next) {
            props.onSelected?.(next);
          }
        }
      }
    };
    document.addEventListener('keypress', handleKeyPress);
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [specs, props]);

  const handleSave = async (specs: InternalShiftSpec[]) => {
    await client.roster.shifts.update(
      props.year,
      props.month,
      props.group,
      specs
    );
    props.onChange?.(specs);
    dialog.close();
  };

  const handleEdit = () => {
    dialog.open(
      <Dialog
        title="Dienste"
        width="w-[650px]"
        onClose={dialog.close.bind(dialog)}
      >
        <ShiftEditor
          group={props.group}
          specs={specs}
          onCancel={() => dialog.close()}
          onSave={handleSave.bind(this)}
        />
      </Dialog>
    );
  };

  return (
    <div className="relative mt-6 min-h-[450px] w-full rounded-md bg-neutral-100 py-1">
      <ul className="flex-col space-y-2 pb-10">
        {specs.map((spec) => (
          <li key={spec.id}>
            <ShiftSpec
              {...spec}
              onSelected={props.onSelected?.bind(this, spec)}
            />
          </li>
        ))}
      </ul>
      <div className="absolute bottom-0 right-0 p-2">
        <LinkButton onClick={handleEdit.bind(this)}>&Auml;ndern</LinkButton>
      </div>
    </div>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */

export type CacheOptions = {
  invalidate?: boolean;
  nocache?: boolean;
  expiresInSeconds?: number;
  drop: string;
};

export class BackendCache {
  private caches = new Map<string, { expires: number; value: any }>();
  private missOrExpired(key: string): boolean {
    const expires = this.caches.get(key)?.expires || 0;
    return expires - Date.now() < 0;
  }

  private inner_set(key: string, value: any, expires = 300): void {
    this.caches.set(key, {
      expires: Date.now() + expires * 1000,
      value
    });
  }
  private inner_get(name: string) {
    return this.caches.get(name)?.value;
  }

  async get<T>(
    key: string,
    getFromRemote: () => Promise<T>,
    options?: CacheOptions
  ): Promise<T> {
    if (options?.nocache) {
      this.clear(key);
      return await getFromRemote();
    }
    if (options?.invalidate) {
      this.clear(key);
    }
    if (this.missOrExpired(key)) {
      if (options?.drop) {
        this.clear(options.drop);
      }
      this.inner_set(key, getFromRemote(), options?.expiresInSeconds);
    }
    return this.inner_get(key);
  }

  clear(key: string) {
    if (key.endsWith('*')) {
      key = key.substring(0, key.length - 1);
      for (const k of this.caches.keys()) {
        if (k.startsWith(key)) {
          this.caches.delete(k);
        }
      }
    } else {
      this.caches.delete(key);
    }
  }

  clearAll() {
    this.caches.clear();
  }

  isEmpty() {
    return this.caches.size === 0;
  }
}

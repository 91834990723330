import { Select, Option, Input } from '@kidsmanager/ui-core';
import { InternalPremiumMap } from './premiums-helpers';

export interface PremiumsSettingsMapProps {
  map: InternalPremiumMap[];
  onChange?: (update: InternalPremiumMap[]) => void;
}

export const PremiumsSettingsMap = (props: PremiumsSettingsMapProps) => {
  const handleCountChange = (
    key: string,
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const item = props.map.find((x) => x.key === key);
    if (item) {
      switch (event.target.value) {
        case 'days':
          item.count = 'days';
          break;
        case 'shifts':
          item.count = 'shifts';
          break;
        case 'hours':
          item.count = 'hours';
          break;
      }
      props.onChange?.([...props.map]);
    }
  };

  const handleCodeChange = (key: string, value: string) => {
    const item = props.map.find((x) => x.key === key);
    if (item) {
      item.code = value;
      props.onChange?.([...props.map]);
    }
  };

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr className="text-xs">
            <th></th>
            <th className="w-32">Anzahl</th>
            <th className="w-20">Lohnart</th>
          </tr>
        </thead>
        <tbody>
          {props.map.map((item, i) => (
            <tr key={i}>
              <td className="px-2 leading-10">
                {item.label}
                {item.tag && (
                  <span className="ml-2 rounded-full bg-orange-500 px-2 py-1 text-sm text-white">
                    {item.tag}
                  </span>
                )}
              </td>
              <td className="px-2">
                <Select
                  defaultValue={item.count}
                  onChange={handleCountChange.bind(this, item.key)}
                >
                  <Option value="days">Tage</Option>
                  <Option value="shifts">Dienste</Option>
                  <Option value="hours">Stunden</Option>
                </Select>
              </td>
              <td>
                <Input
                  value={item.code}
                  onChange={handleCodeChange.bind(this, item.key)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

import {
  IRosterPlan,
  IReleasedMemberPlan,
  IReleasedPlan,
  IRosterDraftSet
} from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiRosterPlan {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  //TODO: return current, prev and next drafts!
  async draft(
    year: number,
    month: number,
    members: { id: string }[]
  ): Promise<IRosterDraftSet> {
    if (!members.length) {
      return { prev: [], current: [], next: [] };
    }

    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get<IRosterDraftSet>(
      `plan-${iso_month}-${members.join('-')}`,
      async () => {
        return (
          await this.fetch(
            `api/roster/plan/draft?month=${iso_month}${members.map(({ id }) => `&id=${id}`).join('')}`
          )
        ).json();
      }
    );
  }

  async update(year: number, month: number, plans: IRosterPlan[]) {
    cache.clearAll();
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    await this.fetch(`api/roster/plan/version/${iso_month}`, {
      method: 'PUT',
      body: JSON.stringify(plans)
    });
  }

  /**
   * Returns the last planned duty roster, released before the from date
   * for the
   * @param from
   * @param days
   * @param userId
   */
  before(from: Date, days: number, userId: string) {
    const iso_from = from.toLocaleISODate();
    return cache.get<unknown[]>(
      `before-${iso_from}-${days}-${userId}`,
      async () =>
        (
          await this.fetch(
            `api/roster/plan/member/before?from=${iso_from}&days=${days}&id=${userId}`
          )
        ).json()
    );
  }

  async releases(groupId: string, year: number, month: number) {
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get<IReleasedPlan[]>(
      `releases-${iso_month}-${groupId}`,
      async () => {
        return (
          await this.fetch(
            `api/roster/plan/release?month=${iso_month}&group=${groupId}`
          )
        ).json();
      }
    );
  }

  async released(
    year: number,
    month: number,
    userId?: string
  ): Promise<IReleasedMemberPlan> {
    userId = userId || 'my';
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get<IReleasedMemberPlan>(
      `released-${iso_month}-${userId}`,
      async () => {
        return (
          await this.fetch(
            `api/roster/plan/member/release?month=${iso_month}&id=${userId}`
          )
        ).json();
      }
    );
  }

  async release(
    groupId: string,
    year: number,
    month: number,
    members: string[]
  ): Promise<IReleasedPlan> {
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    cache.clear(`releases-${iso_month}-${groupId}`);
    return (
      await this.fetch(
        `api/roster/plan/release?month=${iso_month}&group=${groupId}${members.map((member) => `&id=${member}`).join('')}`,
        {
          method: 'POST'
        }
      )
    ).json();
  }

  async unpublish(
    groupId: string,
    year: number,
    month: number,
    version: string,
    members: string[]
  ): Promise<IReleasedPlan> {
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    cache.clear(`releases-${iso_month}-${groupId}`);
    return (
      await this.fetch(
        `api/roster/plan/release/${version}?month=${iso_month}&group=${groupId}${members.map((member) => `&id=${member}`).join('')}`,
        {
          method: 'DELETE'
        }
      )
    ).json();
  }

  async discard(
    groupId: string,
    year: number,
    month: number,
    version: string,
    members: string[]
  ): Promise<IReleasedPlan> {
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    cache.clear(`releases-${iso_month}-${groupId}`);
    cache.clear(`plan-${iso_month}-${members.join('-')}`);
    return (
      await this.fetch(
        `api/roster/plan/version/${version}?month=${iso_month}&group=${groupId}${members.map((member) => `&id=${member}`).join('')}`,
        {
          method: 'DELETE'
        }
      )
    ).json();
  }
}

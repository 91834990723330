export interface IAccessItem {
  title: string;
  href: string;
  id?: string;
}

export interface IAccessMap {
  tenant: string;
  areas: IAccessItem[];
  team: IAccessItem[];
  groups: IAccessItem[];
  admin: IAccessItem[];
  user: {
    firstName: string;
    lastName: string;
    currentGroup?: { id: string; name: string };
    map: IAccessItem[];
  };
}

export const emptyAccessMap: IAccessMap = {
  tenant: '',
  areas: [],
  team: [],
  groups: [],
  admin: [],
  user: {
    firstName: '',
    lastName: '',
    currentGroup: { id: '', name: '' },
    map: []
  }
};

export interface IProfile {
  currentGroup: string;
  mfaDeivceRegistered: boolean;
  vehicleRegistration: string;
  transportMode: 'car' | 'bike' | 'walk' | 'motorbike';

  // archiveColumns: string;
  // FontSize: string;
  // language: string;
  // lastAppotmentType: string;
  // notesFilter: string;
  // notification: string;
  // pinnedProject: string;
  // recentType: string;
  // reorderNamnabled: string;
  // usernameFull: string;
}

export const BlankProfile: IProfile = {
  currentGroup: '',
  mfaDeivceRegistered: false,
  vehicleRegistration: '',
  transportMode: 'car'

  // archiveColumns: '',
  // FontSize: '',
  // language: '',
  // lastAppotmentType: '',
  // notesFilter: '',
  // notification: '',
  // pinnedProject: '',
  // recentType: '',
  // reorderNamnabled: '',
  // usernameFull: ''
};

import { quickid } from '@kidsmanager/ui-api';
import {
  Checkbox,
  ContextMenuButton,
  DialogConfirm,
  DialogContext,
  DialogError,
  InlineInput,
  TableMenu
} from '@kidsmanager/ui-core';
import { IRosterTemplateNonWork } from '@kidsmanager/util-models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeNonWorkOptions } from '../helpers/non-work-helpers';

export interface NonWorkProps {
  options: IRosterTemplateNonWork[];
  onChange?: (options: IRosterTemplateNonWork[]) => void;
}

export const EditorNonWork = (props: NonWorkProps) => {
  const dialog = useContext(DialogContext);
  const [options, setOptions] = useState<IRosterTemplateNonWork[]>([]);
  const { t } = useTranslation('admin-roster');

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  const handleAdd = () => {
    setOptions((prev) => {
      const next = [
        ...prev,
        {
          id: quickid('NW', 'low'),
          name: t('roster.new_non_work'),
          abbreviation: '',
          code: '',
          budget: false,
          infoOnly: false
        }
      ];
      setTimeout(() => props.onChange?.(next), 0);
      return next;
    });
  };
  const handleCopy = () => {
    if (!options) return;
    const forCopy = {
      source: 'kidsmanager:nlz',
      data: options.map((o) => {
        return { ...o, id: undefined };
      })
    };
    navigator.clipboard.writeText(JSON.stringify(forCopy));
  };

  const handlePaste = async () => {
    const fromClipboard = await navigator.clipboard.readText();
    const { data } = fromClipboard.includes('kidsmanager:nlz')
      ? JSON.parse(fromClipboard)
      : {};
    if (!Array.isArray(data)) {
      dialog.open(
        <DialogError onClose={() => dialog.close()}>
          {t('roster.nonwork_invalid_paste')}
        </DialogError>
      );
      return;
    }
    setOptions((prev) => {
      const next = mergeNonWorkOptions(prev, data);
      setTimeout(() => props.onChange?.(next), 0);
      return next;
    });
  };

  const handleEditOption = (value: string) => {
    switch (value) {
      case 'clear':
        setOptions([]);
        props.onChange?.([]);
        break;
      case 'copy':
        handleCopy();
        break;
      case 'paste':
        handlePaste();
        break;
      case 'add':
        handleAdd();
        break;
      default:
        console.warn('Unknown option:', value);
        break;
    }
  };

  const handleDelete = (option: IRosterTemplateNonWork) => {
    setOptions((prev) => {
      const next = prev.filter((o) => o.id !== option.id);
      setTimeout(() => props.onChange?.(next), 0);
      return next;
    });
  };

  const handleMoveUp = (option: IRosterTemplateNonWork) => {
    setOptions((prev) => {
      const index = prev.findIndex((o) => o.id === option.id);
      if (index === 0) return prev;
      const next = [...prev];
      next.splice(index - 1, 0, next.splice(index, 1)[0]);
      setTimeout(() => props.onChange?.(next), 0);
      return next;
    });
  };

  const handleMoveDown = (option: IRosterTemplateNonWork) => {
    setOptions((prev) => {
      const index = prev.findIndex((o) => o.id === option.id);
      if (index === prev.length - 1) return prev;
      const next = [...prev];
      next.splice(index + 1, 0, next.splice(index, 1)[0]);
      setTimeout(() => props.onChange?.(next), 0);
      return next;
    });
  };

  const handleChange = (
    option: IRosterTemplateNonWork,
    key: string,
    value: string | boolean
  ) => {
    setOptions((prev) => {
      const next = prev.map((o) =>
        o.id === option.id ? { ...o, [key]: value } : o
      );
      setTimeout(() => props.onChange?.(next), 0);
      return next;
    });
  };

  return (
    options && (
      <div className="border-silver-200 relative min-h-44 rounded border bg-white p-4 pb-6">
        <table className="mb-8 w-full border-separate border-spacing-y-1">
          <thead className="text-left text-xs">
            <tr>
              <th style={{ minWidth: '250px' }}>{t('roster.name')}</th>
              <th style={{ width: '160px' }}>{t('roster.abbr')}</th>
              <th style={{ width: '140px' }}>{t('roster.booking_code')}</th>
              <th style={{ width: '140px' }}>{t('roster.budget')}</th>
              <th style={{ width: '140px' }}>{t('roster.info_only')}</th>
              <th style={{ width: '40px' }}></th>
            </tr>
          </thead>
          <tbody>
            {options.map((option, index) => (
              <tr key={option.id} className="my-1 odd:bg-neutral-100">
                <td className="w-[160px]">
                  <InlineInput
                    value={option.name}
                    onChange={(val) => handleChange(option, 'name', val)}
                  />
                </td>
                <td>
                  <InlineInput
                    value={option.abbreviation}
                    onChange={(val) =>
                      handleChange(option, 'abbreviation', val)
                    }
                  />
                </td>
                <td>
                  <InlineInput
                    value={option.code}
                    onChange={(val) => handleChange(option, 'code', val)}
                  />
                </td>
                <td className="pl-1 align-bottom">
                  <Checkbox
                    checked={option.budget}
                    onChange={(val) => handleChange(option, 'budget', val)}
                  />
                </td>
                <td className="pl-1 align-bottom">
                  <Checkbox
                    checked={option.infoOnly}
                    onChange={(val) => handleChange(option, 'infoOnly', val)}
                  />
                </td>
                <td className="text-center">
                  <TableMenu
                    options={[
                      {
                        label: t('common.move_up'),
                        disabled: index === 0,
                        callback: handleMoveUp.bind(this, option)
                      },
                      {
                        label: t('common.move_down'),
                        disabled: index === options.length - 1,
                        callback: handleMoveDown.bind(this, option)
                      },
                      {
                        label: t('common.delete'),
                        callback: handleDelete.bind(this, option)
                      }
                    ]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="absolute bottom-0 right-0">
          <ContextMenuButton
            icon="edit"
            color="ghost"
            position="overButton"
            padding="p-1"
            options={[
              { key: 'clear', label: t('common.clear_all'), icon: 'close' },
              {
                key: 'copy',
                label: t('common.copy_all'),
                icon: 'content_copy'
              },
              { key: 'paste', label: t('common.paste'), icon: 'content_paste' },
              { key: 'add', label: t('common.add'), icon: 'add' }
            ]}
            onClick={handleEditOption.bind(this)}
          />
        </div>
      </div>
    )
  );
};

export interface SplitButtonProps {
  disabled?: boolean;
  children: React.ReactNode;
  options: { key: string; label: string; disabled?: boolean }[];
  primaryAction?: string;
  onClick?: (key?: string) => void;
}

export const SplitButton = (props: SplitButtonProps) => {
  const onButtonClick = () => {
    props.onClick?.(props.primaryAction);
  };

  const onSelect = (option: {
    key: string;
    label: string;
    disabled?: boolean;
  }) => {
    if (option.disabled) return;
    props.onClick?.(option.key);
    (document.activeElement as HTMLElement)?.blur();
  };

  return (
    <span
      className={`outline-focus relative inline-flex rounded-md outline-offset-2 ${!props.disabled && 'focus-within:outline'}`}
    >
      <button
        className="border-silver-400 hover:bg-silver-200 flex-1 rounded-l-md border bg-neutral-200 px-2 py-1 text-sm outline-none"
        style={{ opacity: props.disabled ? 0.5 : 1 }}
        disabled={props.disabled}
        onClick={onButtonClick.bind(this)}
      >
        {props.children}
      </button>
      <div
        className={`border-silver-400 hover:bg-silver-200 flex cursor-pointer items-center rounded-r-md border border-l-0 bg-neutral-200 outline-none ${!props.disabled && 'group'}`}
        style={{ opacity: props.disabled ? 0.5 : 1 }}
        tabIndex={0}
      >
        <span className="material-icons">arrow_drop_down</span>

        <ul className="shadow-hdr border-silver-300 absolute right-0 top-8 z-50 hidden min-w-40 border bg-white py-2 outline-none group-focus-within:block">
          {props.options.map((option, index) => (
            <li
              key={index}
              onClick={onSelect.bind(this, option)}
              onKeyDown={(e) => {
                e.key === 'Enter' && onSelect(option);
              }}
              tabIndex={0}
              className="outline-focus min-w-14 cursor-pointer text-nowrap px-4 py-2 text-left text-sm text-black hover:bg-neutral-100"
              style={{ opacity: option.disabled ? 0.5 : 1 }}
            >
              {option.label}
            </li>
          ))}
        </ul>
      </div>
    </span>
  );
};

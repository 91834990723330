export const datadogWorkAround = (e: React.FocusEvent<HTMLElement>) => {
  e.target.classList.toggle('dd-rum');
};

export const determineTextColor = (color: string) => {
  const rgbMatch = color.match(/^rgb\((\d+) \s*(\d+) \s*(\d+)\)$/);
  if (rgbMatch) {
    const r = parseInt(rgbMatch[1], 10);
    const g = parseInt(rgbMatch[2], 10);
    const b = parseInt(rgbMatch[3], 10);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.6 ? '#000000cc' : '#ffffffee';
  } else {
    const r = parseInt(color.substring(1, 3), 16);
    const g = parseInt(color.substring(3, 5), 16);
    const b = parseInt(color.substring(5, 7), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.6 ? '#000000cc' : '#ffffffee';
  }
};

export const getTabDescription = (
  app: string,
  path: string,
  t: (key: string | string[]) => string
) => {
  const url = path.split('/').filter((x) => !!x);
  const key = (() => {
    switch (url[0]) {
      case 'team':
        return null;
      case 'report':
        return `${app}.${url.slice(0, 3).join('.')}`;
      default: {
        const count = app === 'KidsManager' ? 2 : 1;
        return `${app}.${url.slice(0, count).join('.')}`;
      }
    }
  })();
  if (!key) {
    return app;
  }
  const value = t(key);
  return value === key ? app : value;
};

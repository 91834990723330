import { quickid } from '@kidsmanager/ui-api';
import { IRosterTemplateNonWork } from '@kidsmanager/util-models';

export const mergeNonWorkOptions = (
  prev: IRosterTemplateNonWork[],
  data: Omit<IRosterTemplateNonWork, 'id'>[]
) => {
  const next = [...prev];
  data.forEach((o) => {
    const existing = next.find(
      (n) => n.name.toLowerCase() === o.name.toLowerCase()
    );
    if (existing) {
      existing.abbreviation = o.abbreviation;
      existing.code = o.code;
      existing.budget = o.budget;
      existing.infoOnly = o.infoOnly;
    } else {
      next.push({ ...o, id: quickid('NW', 'low') });
    }
  });
  return next;
};

import { RuleSpec } from './rule-spec';

export const ruleSpecsDaily: RuleSpec[] = [
  {
    id: 'max-hours-together',
    category: 'daily',
    defaults: [{ key: 'length', type: 'number', value: '25' }],
    values: [],
    enabled: false
  },

  {
    id: 'before-x-approval',
    category: 'daily',
    defaults: [{ key: 'before', type: 'time', value: '06:00' }],
    values: [],
    enabled: false
  },
  {
    id: 'after-x-approval',
    category: 'daily',
    defaults: [{ key: 'after', type: 'time', value: '22:00' }],
    values: [],
    enabled: false
  },
  {
    id: 'nightshift-required',
    category: 'daily',
    defaults: [
      { key: 'name', type: 'label', value: '' },
      { key: 'from', type: 'time', value: '22:00' },
      { key: 'to', type: 'time', value: '06:00' }
    ],
    values: [],
    enabled: false
  },
  {
    id: 'standby-required',
    category: 'daily',
    defaults: [
      { key: 'name', type: 'label', value: '' },
      { key: 'from', type: 'time', value: '00:00' },
      { key: 'to', type: 'time', value: '06:00' }
    ],
    values: [],
    enabled: false
  },
  {
    id: 'max-without-nightshift',
    category: 'daily',
    defaults: [
      { key: 'name', type: 'from', value: 'standby-required' },
      { key: 'max', type: 'number', value: '10' }
    ],
    values: [],
    enabled: false
  },
  {
    id: 'standby-fixed-payment',
    category: 'daily',
    defaults: [{ key: 'name', type: 'from', value: 'standby-required' }],
    values: [],
    enabled: false
  }
];

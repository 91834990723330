/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { datesOverlap } from './dates-overlap';
import {
  SettingsTable,
  SettingsTableColSpec
} from '../settings-table/settings-table';
import { InlineInput } from '../input/inline-input';
import { useTranslation } from 'react-i18next';

export interface SettingsTableRangeProps {
  title: string;
  meta?: React.ReactNode;
  disableOverlapCheck?: boolean;
  cols: ColumnType[];
  values: ValueType[];
  onChange?: (values: any[], overlaps: boolean) => void;
  onAdd?: () => void;
  onRemove?: (id: string) => void;
}

type ValueType = Record<string, any> & {
  id: string;
  from: string;
  to?: string;
};
export type ColumnType = {
  key: string;
  type: 'string' | 'number';
  label: string;
};

const toLocaleDateString = (date?: string, locale?: string): string => {
  if (!date) {
    return '';
  }
  locale = locale === 'en' ? 'en-GB' : 'de-DE';
  return new Date(date).toLocaleDateString(locale, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  });
};

export const SettingsTableRange = (props: SettingsTableRangeProps) => {
  const { i18n } = useTranslation();

  const columns = useMemo<SettingsTableColSpec[]>(() => {
    const cols = props.cols.map(
      (col) => ({ ...col, minWidth: '75px' }) as SettingsTableColSpec
    );
    cols.push({ label: 'Von', width: '120px' });
    cols.push({ label: 'Bis', width: '120px' });
    cols.push({ label: '', width: '30px', maxWidth: '30px' });
    return cols;
  }, [props.cols]);

  const hasOverlap = useMemo(() => {
    if (props.disableOverlapCheck) {
      return false;
    }
    return datesOverlap(props.values);
  }, [props.values, props.disableOverlapCheck]);

  const items = useMemo(
    () =>
      props.values.map((v) => {
        const from = toLocaleDateString(v.from, i18n.language);
        const to = toLocaleDateString(v.to, i18n.language);
        const item: ValueType = { id: v.id, from, to };
        props.cols.forEach((col) => {
          item[col.key] = v[col.key];
        });
        return item;
      }),
    [props.values, props.cols, i18n.language]
  );

  const notifyChange = (
    id: string,
    key: string,
    value: string | number | undefined
  ) => {
    const updateList = structuredClone(props.values);
    const updateItem = updateList.find((x) => x.id === id);
    if (!updateItem) {
      return;
    }
    updateItem[key] = value;
    props.onChange?.(updateList, datesOverlap(updateList));
  };

  const handleChangeValue = (
    col: ColumnType,
    { id }: ValueType,
    newValue: string
  ) => {
    switch (col.type) {
      case 'number':
        notifyChange(id, col.key, Number(newValue));
        break;
      default:
        notifyChange(id, col.key, newValue);
    }
  };

  const handleChangeFrom = (value: ValueType, from: string) => {
    const [dd, mm, yyyy] = from.split('.');
    notifyChange(value.id, 'from', `${yyyy}-${mm}-${dd}`);
  };

  const handleChangeTo = (value: ValueType, to: string) => {
    if (!to) {
      notifyChange(value.id, 'to', undefined);
    } else {
      const [dd, mm, yyyy] = to.split('.');
      notifyChange(value.id, 'to', `${yyyy}-${mm}-${dd}`);
    }
  };

  return (
    <>
      <SettingsTable
        label={props.title}
        meta={props.meta}
        columns={columns}
        onAdd={props.onAdd?.bind(this)}
      >
        {items.map((item) => (
          <tr key={`${item.id}`} className="group">
            {props.cols.map((col) => (
              <td key={col.key} className="w-8">
                <InlineInput
                  mask={col.type === 'number' ? 'number' : undefined}
                  value={item[col.key]?.toString() || ''}
                  onChange={(val) => handleChangeValue(col, item, val)}
                />
              </td>
            ))}
            <td className="w-24">
              <span className="inline-block w-24">
                <InlineInput
                  mask="date"
                  value={item.from}
                  onChange={(from) => handleChangeFrom(item, from)}
                />
              </span>
            </td>
            <td className="w-24">
              <span className="inline-block w-24">
                <InlineInput
                  mask="date"
                  value={item.to || ''}
                  onChange={(to) => handleChangeTo(item, to)}
                />
              </span>
            </td>
            <td className="flex items-center">
              <span
                className="material-icons cursor-pointer opacity-0 group-hover:opacity-100"
                onClick={props.onRemove?.bind(this, item.id)}
              >
                close
              </span>
            </td>
          </tr>
        ))}
      </SettingsTable>
      <p className="text-error h-4 text-right text-xs">
        {hasOverlap && <span>Datumsbereiche überschneiden sich</span>}
      </p>
    </>
  );
};

import { useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { getTabDescription, Header } from '@kidsmanager/ui-core';
import { Role } from '@kidsmanager/util-storage';
import { IAccessMap } from '@kidsmanager/util-models';
import { useTranslation } from 'react-i18next';

export interface AppGuardProps {
  requires?: Role;
}

export const AppGuard = ({ requires }: AppGuardProps) => {
  const client = useContext(ClientBackendContext);
  const params = useParams<{ group: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('features');

  const [accessMap, setAccessMap] = useState<IAccessMap>();
  const groupId = useMemo(() => {
    return params.group;
  }, [params.group]);

  useEffect(() => {
    client.settings.accessMap().then((map) => {
      if (requires && !client.auth.inRole(requires)) {
        console.warn(`'${requires}' permission missing for route`);
        navigate('/');
      } else if (groupId && !map.groups.find((g) => g.id === groupId)) {
        console.warn(`User has no access to group '${groupId}'`);
        navigate('/');
      } else {
        const tabName = getTabDescription('KidsManager', location.pathname, t);
        document.title = `${map.tenant} | ${tabName}`;
        setAccessMap(map);
      }
    });
  }, [client, navigate, requires, groupId, location, t]);

  return (
    accessMap && (
      <>
        <Header {...accessMap} />
        <Outlet />
      </>
    )
  );
};

import { Input, Select, Option } from '@kidsmanager/ui-core';

export interface ColumnEditorProps {
  type: string;
  label: string;
  value?: string;
  data: { key: string; label: string }[];
  onChange?: (type: string, label: string, value?: string) => void;
  onDelete?: () => void;
}

export const ExportColumnEditor = (props: ColumnEditorProps) => {
  const handleChangeLabel = (value: string) => {
    props.onChange?.(props.type, value, props.value);
  };

  const handleChangeType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    props.onChange?.(e.target.value, props.label, props.value);
  };

  const handleChangeValue = (value: string) => {
    props.onChange?.(props.type, props.label, value);
  };

  return (
    <div className="group grid grid-cols-[1fr_210px_24px] items-center gap-2">
      <Input value={props.label} onChange={handleChangeLabel.bind(this)} />
      <div className="flex gap-2">
        <Select
          defaultValue={props.type}
          onChange={handleChangeType.bind(this)}
        >
          <Option disabled value="">
            -- Wert auswählen --
          </Option>
          {props.data.map((item, i) => (
            <Option key={i} value={item.key}>
              {item.label}
            </Option>
          ))}
          <Option value="constant">Konstante</Option>
        </Select>
        {props.type === 'constant' && (
          <span>
            <Input
              value={props.value}
              onChange={handleChangeValue.bind(this)}
            />
          </span>
        )}
      </div>
      <span
        tabIndex={0}
        className="material-icons outline-focus cursor-pointer opacity-20 transition-opacity hover:opacity-60 group-focus-within:opacity-60"
        onClick={props.onDelete?.bind(this)}
      >
        close
      </span>
    </div>
  );
};

import { datadogWorkAround } from '../helper';
import { IconButton } from './icon-button';

export interface ContextMenuItem {
  key: string;
  label: string;
  icon?: string;
  disabled?: boolean;
}

export interface ContextMenuButtonProps {
  disabled?: boolean;
  options: ContextMenuItem[];
  onClick?: (value: string) => void;
  icon?: string;
  padding?: string;
  color?: 'ghost';
  position?: 'overButton';
  style?: 'square' | 'round';
}

export const ContextMenuButton = (props: ContextMenuButtonProps) => {
  const handleClick = (option: ContextMenuItem) => {
    if (option.disabled === true) {
      return;
    }
    if (props.onClick) {
      props.onClick(option.key);
    }
    (document.activeElement as HTMLElement)?.blur();
  };
  const hasIcons = props.options.some((option) => option.icon);

  return (
    <div className="">
      <div
        tabIndex={0}
        className={`outline-focus relative inline-flex cursor-pointer items-center outline-offset-2 ${!props.disabled && 'group'} ${props.padding}`}
        onFocusCapture={datadogWorkAround}
        onBlurCapture={datadogWorkAround}
      >
        <IconButton color={props.color} disabled={props.disabled}>
          {props.icon || 'more_vert'}
        </IconButton>
        <ul
          className="shadow-hdr border-silver-300 absolute right-0 top-8 z-50 hidden border bg-white py-2 text-left group-focus-within:block"
          style={{
            top: props.position === 'overButton' ? 'unset' : '2rem',
            bottom: props.position === 'overButton' ? '0' : 'unset',
            right: props.position === 'overButton' ? '0' : '0'
          }}
        >
          {props.options.map((option) => (
            <li
              key={option.key}
              className="flex min-w-14 items-center text-nowrap px-4 py-2 text-sm hover:bg-stone-200"
              style={{ opacity: option.disabled ? 0.5 : 1 }}
              onClick={handleClick.bind(this, option)}
            >
              {hasIcons && (
                <>
                  <span className="material-icons mr-2 w-8 text-base">
                    {option.icon}
                  </span>
                  <span>{option.label}</span>
                </>
              )}
              {!hasIcons && option.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
